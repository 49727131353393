<template>
  <b-container>
    <b-card no-body>
      <div class="p-2 d-flex justify-content-between">
        <b-card-title class="m-0">{{ T("Web.Generic.Accounts") }}</b-card-title>
      </div>
      <b-table
        v-if="userProfileLocal != null"
        show-empty
        :empty-text="T('Web.Generic.TableEmpty')"
        :fields="tableFields"
        :items="accountsList"
      >
        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <b-button variant="flat-primary" @click="swapAccount(data.item)"
              >{{ T("Web.LoginPage.LoginBtn") }}
              <feather-icon icon="KeyIcon" size="16" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BContainer,
  BCard,
  BTable,
  BSpinner,
  BButton,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BCardTitle,
    BCard,
    BTable,
    BSpinner,
    BButton,
  },
  computed: {
    ...mapGetters({
      userProfile: "appUser/getCurrentProfile",
      accountScope: "appUser/getCurrentAccountScope",
    }),
    userProfileLocal()
    {
      return this.userProfile;
    },

    accountsList() {
      let accounts = [...this.userProfile.accounts];
      return accounts
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((a) => a.directoryId == this.accountScope.id);
    },
    tableFields() {
      return [
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      switchAccountAsync: "appUser/switchAccountScopeAsync",
    }),
    async swapAccount(account) {
      await this.switchAccountAsync(account.id);
    },
  },
};
</script>